import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

import IcoDownload from "assets/icons/download-v3.svg"

const File = () => {
  return (
    <section className="friends-file">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-4 col-lg-6">
            <h3>
              Zaproś znajomych
              <br /> pod ten sam adres.
            </h3>
          </div>

          <div className="col-lg-6 offset-xl-2">
            <div className="friends-file__item">
              <div>
                <p>Więcej szczegółów w regulaminie</p>
                <a
                  href={withPrefix(
                    "/Regulamin_Programu_Polecen_Trust_Investment.pdf"
                  )}
                  download
                >
                  Pobierz <img src={IcoDownload} alt="" />
                </a>
              </div>
              <div className="friends-file__item-cover">
                <img
                  src={
                    require("assets/images/friends/friends-file-cover.png")
                      .default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default File
