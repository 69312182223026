import "./styles.scss"

import React from "react"

import Breadcrumbs from "components/Breadcrumbs"

const HeaderBackground = ({ background, title, description }) => {
  return (
    <section
      className="header-background"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Breadcrumbs title={title} isLight />
      <div className="header-background__wrapper">
        <div className="container-fluid">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </section>
  )
}

export default HeaderBackground
